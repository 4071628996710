/*! CSS Used from: http://labs.voronianski.com/react-soundplayer/build/styles.min.css?03102017 */

div.player-simple {
    max-width: 100%;
	display: flex;
	border: 1px solid #000;
	margin: 0 auto;
	margin-bottom: 2rem;
	background: white;

    h6 {
        font-size: 1rem;
        padding-left: 1.5rem;
    }
}

.sb-soundplayer-icon {
    width: 1em;
    height: 1em;
    position: relative;
    vertical-align: middle;
}

svg {
    max-height: 100%;
}

@include media-breakpoint-up(sm) { 
    div.player-simple {
    max-width: 90%;
    }
}

.button,
button {
    font-family: inherit;
    font-size: inherit;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 8px 16px;
    padding: .5rem 1rem;
    margin: 0;
    height: auto;
    border: 1px solid transparent;
    vertical-align: middle;
    -webkit-appearance: none;
}

.button,
.button:after,
.button:before,
button,
button:after,
button:before {
    box-sizing: border-box;
}

::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.button:hover {
    text-decoration: none;
}

h2 {
    font-family: Avenir Next, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: black;
}

h2 {
    font-weight: 700;
    line-height: 1.25;
    margin-top: 1em;
    margin-bottom: .5em;
}

h2 {
    font-size: 24px;
    font-size: 1.5rem;
}

.nowrap {
    white-space: nowrap;
}

.m0 {
    margin: 0;
}

.mt1 {
    margin-top: 8px;
    margin-top: .5rem;
}

.mr1 {
    margin-right: 8px;
    margin-right: .5rem;
}

.mr2 {
    margin-right: 16px;
    margin-right: 1rem;
}

.mb3 {
    margin-bottom: 32px;
    margin-bottom: 2rem;
}

.p1 {
    padding: 8px;
    padding: .5rem;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.flex-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-auto {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.flex-none {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
}

.flex>div {
    box-sizing: border-box;
}
    
.rounded {
    border-radius: 3px;
}

.orange {
    color: #fc561e;
}

.bg-darken-1 {
    background-color: rgba(0, 0, 0, .0625);
}

.sb-soundplayer-btn div {
    background: black;
}

.sb-soundplayer-timer{
    padding-top: 0.5rem !important;
}