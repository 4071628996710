#music {
    padding-top: 8rem;
    padding-bottom: 8rem; 
    h1 {
        font-family: "Helvetica Neue";
        font-weight: 100;
        font-size: 60px;
        padding-bottom: 2rem;

    }
    
    h3 {
        color: black;
    }

    .btn-beast {
		// background: $selk-cyan;
     margin: 0 auto !important;   
    width: 90%;
    border: 1px solid #000;

  }
} 

.beast-inverse {
    max-width: 90%;
}

.col-singles {
    text-align: left;
}