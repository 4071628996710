$social-size-lg:6rem;
$social-size-md:3rem;
$social-size-sm:3rem;


.social {
    margin: 2rem auto;
    margin-bottom: 0rem;
}

.social a:hover {
    text-decoration: none;
}

.social-icon {
    width: $social-size-sm;
    height: $social-size-sm;
    margin:0.25rem 0.25rem 0.25rem 0.25rem;
    transition: 0.3s all;
}

.social-icon:hover {
    transform: scale(1.25);
}

.social-anim {
    // opacity: 0;
}

.social-contact {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.social-icon-contact {
    margin: 0.25rem 1rem 0.25rem 1rem;
    width: $social-size-lg*0.6;
    height: $social-size-lg*0.6;
    opacity: 1;
}

@include media-breakpoint-up(sm) { 
    .social-icon{
        margin:0.25rem 1.5rem 0.25rem 1.5rem;
        width: $social-size-md;
        height: $social-size-md;
    }

    .social-icon-contact {
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
        width: $social-size-sm;
        height: $social-size-sm;
    }
}

@include media-breakpoint-up(md) { 
    .social-icon{
        // margin:0.25rem 3rem 0.25rem 2rem;
        width: $social-size-md;
        height: $social-size-md;
    }

}


