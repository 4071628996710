section#beast {
  padding-bottom: 6rem;
}

.img-beast {
  max-width: 90%;
  // box-shadow: 0.5rem 1rem 1rem 0.5rem rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  margin: 0 auto;
}

.btn-beast {
  border: 4px solid #000;
  margin: 2rem 2rem 0rem 2rem;
  padding: 1rem 2.5rem 1rem 2.5rem;
  min-width: 14rem;
  background: #fff;
  color: black;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
}

.btn-beast:hover {
  background: #000;
  color: white;
}

@include media-breakpoint-up(md) {
  .img-beast {
    max-width: 80%;
  }
}