
body {
	letter-spacing: 0.2em;
}

h1, h2 {
	font-weight: 400;
	color: #666;
}

h3, h4, h5, h6 {
	font-weight: 400;
	color: #666;
}
