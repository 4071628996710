.player-header {
    text-align: left;
    padding: 0rem 0rem 1rem 1rem;

    h2 {
        margin-bottom: 2rem;
        text-transform: uppercase;
        //  font-weight: 300;
        color: black;
    }
}

.selk-track {
    // font-weight: 100;
    // border-bottom: 1px dashed #000;
    padding-bottom: 0.5rem;
    font-weight: 400;
    transition: all 0.5s;
}

.button-track {
    border-top: 1px solid #ccc !important;  
    padding-top: 1rem !important;
}

.selk-player-ui {
    padding-bottom: 2rem;
}

.soundcloud-player-container {
    max-width: 100%;
    margin: 0 auto;
    // background: #ccc;
    margin-bottom: 4rem;
    padding: 1rem;
    border: 1px solid #000;
    // box-shadow: 0.5rem 1rem 1rem 0.5rem rgba(0, 0, 0, 0.15);
}

@include media-breakpoint-up(sm) { 
    .soundcloud-player-container {
    max-width: 90%;
    }
}


/*! CSS Used from: https://labs.voronianski.com/react-soundplayer/build/styles.min.css?03102017 */

.sb-soundplayer-icon {
    width: 1em;
    height: 1em;
    position: relative;
    vertical-align: middle;
}

.sb-soundplayer-progress-container {
    background-color: #eff2f1;
    width: 100%;
    height: 8px;
    overflow: hidden;
    cursor: pointer;
}

.sb-soundplayer-progress-inner {
    background-color: $selk-cyan;
    height: 100%;
    transition: width .2s ease-in;
}

.sb-soundplayer-volume {
    position: relative;
}

.sb-soundplayer-volume:hover div {
    width: 80px;
}

.sb-soundplayer-volume div {
    overflow: hidden;
    width: 0;
    transition: width .2s ease-out;
    position: relative;
}

.sb-soundplayer-volume-range {
    -webkit-appearance: none;
    width: 98%;
}

.sb-soundplayer-volume-range:focus {
    outline: none;
}

svg {
    max-height: 100%;
}

input {
    font-family: inherit;
    font-size: 16px;
    font-size: 1rem;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0;
}

.button,
button {
    font-family: inherit;
    font-size: inherit;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 8px 16px;
    padding: .5rem 1rem;
    margin: 0;
    height: auto;
    border: 1px solid transparent;
    vertical-align: middle;
    -webkit-appearance: none;
}

.button,
.button:after,
.button:before,
button,
button:after,
button:before {
    box-sizing: border-box;
}

::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.button:hover {
    text-decoration: none;
}

h2 {
    font-weight: 700;
    line-height: 1.25;
    margin-top: 1em;
    margin-bottom: .5em;
}

.h2,
h2 {
    font-size: 24px;
    font-size: 1.5rem;
}

.h3 {
    font-size: 20px;
    font-size: 1.25rem;
}

.h4 {
    font-size: 16px;
    font-size: 1rem;
}

.h6 {
    font-size: 12px;
    font-size: .75rem;
}

.full-width {
    width: 100%;
}

.regular {
    font-weight: 400;
}

.caps {
    text-transform: uppercase;
    letter-spacing: .2em;
}

.left-align {
    text-align: left;
}

.nowrap {
    white-space: nowrap;
}

.m0 {
    margin: 0;
}

.mt0 {
    margin-top: 0;
}

.mt1 {
    margin-top: 8px;
    margin-top: .5rem;
}

.mr1 {
    margin-right: 8px;
    margin-right: .5rem;
}

.mb1 {
    margin-bottom: 8px;
    margin-bottom: .5rem;
}

.mr2 {
    margin-right: 16px;
    margin-right: 1rem;
}

.mb2 {
    margin-bottom: 16px;
    margin-bottom: 1rem;
}

.mb3 {
    margin-bottom: 32px;
    margin-bottom: 2rem;
}

.p2 {
    padding: 16px;
    padding: 1rem;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.flex-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-auto {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.flex-none {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
}

.flex>div {
    box-sizing: border-box;
}

input[type=range] {
    vertical-align: middle;
    background-color: transparent;
}

.button-transparent {
    position: relative;
    z-index: 2;
    color: inherit;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid transparent;
}

.button-transparent:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 1px solid transparent;
    background-color: currentcolor;
    opacity: 0;
}

.button-transparent:hover {
    box-shadow: none;
}

.button-transparent:focus:before,
.button-transparent:hover:before {
    opacity: .09375;
}

.button-transparent:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px;
}

.button-transparent:active {
    box-shadow: none;
}

.button-transparent:active:before {
    opacity: .0625;
}

.button-transparent:disabled {
    opacity: .5;
}

.rounded {
    border-radius: 3px;
}

.rounded-left {
    border-radius: 3px 0 0 3px;
}

.red {
    color: black;
}

.bg-darken-1 {
    background-color: rgba(0, 0, 0, .0625);
}

.semibold {
    font-weight: 600;
}

.is-active {
    background: $selk-cyan;
    border: none !important;
    color: white;
}