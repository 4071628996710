.press {
	padding-top: 4rem;
    padding-bottom: 4rem;
	background: black;
	color: #ccc;

}

.press h2 {
	color: #ccc;
	font-weight: 100;
}

.press h6 {
	margin-bottom: 2rem;
}

.press a {
	color: #666;
}

.press a:hover {
	color: #6ab3e6;
	text-decoration: none;
}