section#home {
    padding-top: 4rem;

    .social-icon {
        opacity: 0;
    }

}

#logo-home {
    width: 9rem;
    // transition: 0.5s all;
}

.f {
    opacity: 0;
}

.container-home {
    padding: 2rem 0.2rem 8rem 0.2rem;
}

.container-home h3 {
    font-size: rem;
    letter-spacing: 0.4rem;
    // font-weight: 700;
    // color: black;
}


#arrow {
    margin-top: 4rem;
    opacity: 0;
}


@include media-breakpoint-up(xs) {
    #logo-home {
        width: 10rem;
    }

    .container-home h3 {
        line-height: 4rem;
        font-size: 21px;
    }
}

@include media-breakpoint-up(md) {
    .container-home {
        padding: 5rem 0.5rem 14rem 0.5rem;
    }

    #logo-home {
        width: 18rem;
        margin-bottom: 1rem;
    }

    .container-home h3 {
        line-height: 4rem;
        font-size: 1.5rem;
    }
}

@include media-breakpoint-up(lg) {
    .arrow {
        margin-top: 6rem;
    }

    .container-home {
        padding: 5rem 0.5rem 5rem 0.5rem;
    }
}