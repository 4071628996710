section#events {
    // background: #000;
    background: #fff;
    padding: 6rem 0rem 6rem 0rem; // color: #ccc;
    text-align: left;
    border-bottom: 1px solid #ccc;

    h4 {
        text-transform: uppercase;
        font-weight: 700;
        color: black;
        padding-bottom: 1rem;
    }
}

.container-events {
    max-width: 900px;
    margin: 0 auto;

    a {
      color: #00c4d8;
      color: #000;
      border-bottom: 1px solid;
      border-bottom-style: dashed;
      padding-bottom: 0.125em;
    }
}

div.no-upcoming-events {
    padding-top: 2rem;
    margin-bottom: 4rem;

    h1 {
        color: #ccc;
        font-size: 1.5rem;
    }
}

section#events h4 {
    color: #000;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 20px;
}

.table-events {
    margin: 0 auto;
    margin-bottom: 4rem;
    text-align: left;
    
    tr td{
       vertical-align: middle !important;
      //  background: #000;
    }
    a {
        // text-decoration-style: dotted !important;
    }

    tr td:first-child {
        width: 8rem;
    }
    td {
        font-size: 0.66rem;
        // max-width: 2.5rem;
    }
}

@include media-breakpoint-up(sm) { 
    .table-events td {
        font-size: 1rem;
    }

    tr td:first-child {
        width: 8rem;
    }
}
