.selk-letter {
    fill: #bd1b20;
}

.selk-letter-s {
    fill: none;
    stroke: #bd1b20;
    stroke-miterlimit: 10;
    stroke-width: 5.75px;
}

#selk-letter-s {
    opacity: 0;
}

#e-bottom {
    opacity: 0;
}

#e-middle {
    opacity: 0;
}

#e-top {
    opacity: 0;
}

#l-vertical {
    opacity: 0;
}

#l-horizontal {
    opacity: 0;
}

#k-line {
    opacity: 0;
}

#k-line {
    opacity: 0;
}

#k-top {
    opacity: 0;
}

#k-bottom {
    opacity: 0;
}