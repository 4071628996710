#contact {
    // padding: 2rem 1rem 2rem 1rem;
    padding-top: 4rem !important;
    padding-bottom: 4rem;
    border-bottom: 1px solid #ccc;
    background: #fff;

    .social {
        margin-bottom: 2rem;
    }

    .social-icon {
        opacity: 0.8;
        transition: 0.3s all;
    }

    .social-icon:hover {
        opacity: 1;
        transform: scale(1.25);
    }
}

#band-img {
  // position: absolute;
  // top: 0;
  // z-index: 1;
  // margin-bottom: 4rem;
  // opacity: 0.5;
    }

.ams-logo-wrapper {
    border-radius: 20px;
    text-align: center;
    h4 {
        color: #ccc;
        font-size: 1.125rem;
    }
}

#ams {
    max-width: 3rem;
    padding-bottom: 2rem;
}