@import "bootstrap/bootstrap";
@import "variables";
@import "typography";
@import "social";
@import "home";
@import "home_logo";
@import "beast";
@import "video";
@import "music";
@import "press";
@import "events";
@import "contact";
@import "soundcloud_player";
@import "last_page";
@import "form";
@import "video_player";

html,
body {
  height: 100%;
  z-index: -1;
}

body {
  font-family: "Raleway", "Helvetica Neue", Arial;
  font-weight: 400;
  text-align: center;
  color: #000;
  letter-spacing: 0;
  overflow-x: hidden;
}

section {
  padding: 2rem 0.5rem 4rem;
}

/* TODO move */
.latest-single-link {
  position: relative;
  bottom: 2rem;
}